
import { Vue, Component, Watch } from "vue-property-decorator";
import { i18n } from "@/main";
import { voucherGroupStore } from "@/store/modules/voucherGroup";

@Component
export default class VoucherTnC extends Vue {
  private voucher: undefined | null = null;

  get language() {
    return this.$i18n.locale;
  }

  async activated() {
    const { voucherId = "" } = this.$route.params;
    this.voucher = await voucherGroupStore.fetchVoucherGroup(
      voucherId as string
    );
  }
}
